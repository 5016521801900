import React, { useState, useContext, useEffect } from "react";
import { useForm } from "./hooks/useForm";
import { useNavigate, Link } from "react-router-dom";
import UserService from './Services/user-service';
import { UserContext } from "./UserContext";
import Loader from './Loader/Loader'
import logo from "./dst-graphic-transparent.png";

export default function Register() {
  const { user, processLogin } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { values, handleChange } = useForm({ email: "", username: "", password: "", password_retyped: "" });
  let theme = user.theme

  useEffect(() => {
    if (values.password !== values.password_retyped){
    setError("passwords do not match")
  }
    if (values.password === values.password_retyped){
    setError(null)
  }

  }, [values.password, values.password_retyped])
  

  const handleSubmit = (e) => {
    const { username, email, password, password_retyped } = values;
    let lowEmail = email.toString().toLowerCase()

    e.preventDefault();
    setLoading(true);

    UserService.createUser({
      username,
      email: lowEmail,
      password
    })
      .then(res => {
        navigate('/login');
      })
      .catch(res => {
        setLoading(false)
        setError(res.error);
      });
  }

  return (
    
    ( loading ? <Loader /> :
      <div className="Register">
        <img src={logo} className="graphic" alt="logo" />

        <div role='alert' className='error-message'>
          {error && <p>{error}</p>}
        </div>

        <form className='form' onSubmit={handleSubmit}>

          <div className="input_box column">
            <label className={`text ${theme}`}>Username</label>
            <input
              className='login-input'
              autoComplete="new-password"
              type="text"
              name="username"
              placeholder="Enter username..."
              onChange={handleChange}
              value={values.username}
            />
          </div>

          <div className="input_box column">
            <label className={`text ${theme}`}>Email</label>
            <input
              className='login-input'
              autoComplete="new-password"
              type="email"
              name="email"
              placeholder="Enter email..."
              onChange={handleChange}
              value={values.email}
            />
          </div>

          <div className="input_box column">
            <label className={`text ${theme}`}>Password</label>
            <input
              className='login-input'
              autoComplete="new-password"
              type="password"
              name="password"
              placeholder="Enter password..."
              onChange={handleChange}
              value={values.password}
            />
          </div>

          <div className="input_box column">
            <label className={`text ${theme}`}>Retype Password</label>
            <input
              className='login-input'
              autoComplete="new-password"
              type="password"
              name="password_retyped"
              placeholder="Retype password..."
              onChange={handleChange}
              value={values.password_retyped}
            />
          </div>

          <button type="submit" className='loginButton buy_btn' id="login_btn">Register</button>


        </form>
        <Link to='/login'>Already have an account? Login</Link>
      </div>)
  )
}
