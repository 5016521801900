import CONFIG from "../config";

const AuthApiService = {
  postUser(user) {
    console.log(user);
    return fetch(`${CONFIG.REACT_APP_URL}/users`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        
      },
      body: JSON.stringify(user),
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(e => Promise.reject(e))
          : res.json()
      )
      .then(res => 
        window.localStorage.setItem('user', JSON.stringify(res))
      )
  },
  postLogin({ email, password}) {
    return fetch(`${CONFIG.REACT_APP_URL}/auth`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(err => Promise.reject(err))
          : res.json()
      )
  },
};

export default AuthApiService;
