// console.log(process.env.NODE_ENV);

const CONFIG = ( process.env.NODE_ENV === 'production' ? {

  REACT_APP_URL: process.env.REACT_APP_URL,
  REACT_APP_JWT_SECRET: process.env.REACT_APP_JWT_SECRET,
  REACT_APP_TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY
} : {
  REACT_APP_URL: process.env.REACT_APP_URL_LOCAL,
  REACT_APP_JWT_SECRET: process.env.REACT_APP_JWT_SECRET,
  REACT_APP_TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY
})

export default CONFIG