import React from "react";
import CONFIG from '../config';
import TokenService from './token-service';

const UserService = {
  
  getUser() {
    return fetch(`${CONFIG.REACT_APP_URL}/users`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`
      }
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(err => Promise.reject(err))
          : res.json()
      )
  },
  async getUserById(id) {
    return fetch(`${CONFIG.REACT_APP_URL}/user/${id}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`
      },
    })
    .then(res =>
      (!res.ok)
        ? res.json().then(e => Promise.reject(e))
        : res.json()
    );
  },
  getUsers() {
    return fetch(`${CONFIG.REACT_APP_URL}/users`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`
      },
    })
      .then(res => {
        if (!res.ok) {
          res.json().then(e => Promise.reject(e))
        } else {
          res.json()
        }
      }
      );
  },
  createUser(user) {
    return fetch(`${CONFIG.REACT_APP_URL}/users`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(user),
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(err => Promise.reject(err))
          : res.json()
      )
  },
  async updateProfilePic(body) {
    console.log(TokenService.getAuthToken());
    const headers = {
      'bearer': TokenService.getAuthToken()
    }
    return await fetch(`${CONFIG.REACT_APP_URL}/users/profile-image`, {
      method: 'PUT',
      headers,
      body,
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(e => Promise.reject(e))
          : res.json()
      )
     
  },

}
export default UserService;
