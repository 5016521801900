import React from "react";
import { useContext } from "react";
import { UserContext } from "./UserContext";

function About() {

  const { user } = useContext(UserContext);
  let theme = user.theme
  return (
    <div className="About">
      <h2 className={` `}>About Us</h2>

      <p className={`about_text `}>
        Welcome to Dizzle Sol, where innovation meets dedication. Founded by a
        passionate group of blockchain enthusiasts, Dizzle Sol represents the
        culmination of our collective vision to revolutionize the digital
        economy. 
        <br></br>
      </p>
    </div>
  );
}

export default About;