import React, { useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "./UserContext";

export default function Dashboard() {
  const { user, processRefreshUser } = useContext(UserContext);
  const navigate = useNavigate();

  let theme = user.theme

  useEffect(() => {
    if (user) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    processRefreshUser()
  }, [])

  let win_rate = user.wins / (user.loses + user.wins) * 100
  let lose_rate = user.loses / (user.loses + user.wins) * 100

  return (
    <div>
      {user && <div className="Dashboard column">
        <h3 className={`text  ${theme}`}>Welcome Back, {user.username}!</h3>

        <section className="stat_box_dash row">

          <div className="stat_card column">
            <p className={`text`}>Wins</p>
            <p className={`stat  green`}> {user.wins}</p>
          </div>
          <div className="stat_card column">
            <p className={`text`}>Loses</p>
            <p className={`stat  red`}> {user.loses}</p>
          </div>
          <div className="stat_card column">
            <p className={`text`}>Win Rate</p>
            <p className={`stat  green`}> {isNaN(win_rate) ? 0 : Math.round(win_rate)}%</p>
          </div>
          <div className="stat_card column">
            <p className={`text`}>Lose Rate</p>
            <p className={`stat  red`}> {isNaN(lose_rate) ? 0 :Math.round(lose_rate)}%</p>
          </div>
        </section>

        <Link to={'/game'}>
          <button className="buy_btn">Play</button>
        </Link>
      </div>}
    </div>
  )
}
