import { jwtDecode } from 'jwt-decode';
import CONFIG from '../config';

const TokenService = {
  saveAuthToken(token) {
     window.localStorage.setItem(CONFIG.REACT_APP_TOKEN_KEY, token);
  },
  saveUser(user) {
    window.localStorage.setItem('user', user);
  },
  getUser() {
    return window.localStorage.getItem('user');
  },
  getAuthToken() {
    return window.localStorage.getItem(CONFIG.REACT_APP_TOKEN_KEY);
  },
  clearUser() {
     window.localStorage.removeItem('user');
  },
  clearAuthToken() {
     window.localStorage.removeItem(CONFIG.REACT_APP_TOKEN_KEY);
  },
  hasAuthToken() {
    return !!TokenService.getAuthToken();
  },
  parseJwt(jwt) {
    return jwtDecode(jwt);
  },
  parseAuthToken() {
    const authToken = TokenService.getAuthToken();
    if (authToken)
      return TokenService.parseJwt(authToken);
    else
      return undefined;
  },
};

export default TokenService;