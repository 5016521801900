import React from 'react'
import { useState, useContext } from "react";
import { UserContext } from "./UserContext";
import { Link } from "react-router-dom";
import logo from "./dst.png";

export default function Header() {
  const { user, setUser, isLoggedIn, setLoggedIn, processLogout } = useContext(UserContext);
  let theme = user.theme
  const [toggle, setToggle] = useState(false);
  // console.log("Logged In:", isLoggedIn);

  return (
    <header className="Header animate__animated animate__fadeInDown">
      <div className='header row'>
      <div className="logo_box">
        <Link to="/">
          <img src={logo} className="App-logo" alt="logo" />
        </Link>
        <h1 className="title">DIZZLE SOL</h1>
      </div>

      <div className="nav">
      {/* {theme === "light" && (
        <i className="material-icons icon" onClick={() => { setUser({ ...user, theme: "dark" }) }}> light_mode
        </i>
      )}
      {theme === "dark" && (

        <i className="material-icons icon" onClick={() => { setUser({ ...user, theme: "light" }) }}> nights_stay
        </i>
      )} */}


      {!toggle && (<i className="material-icons ham" onClick={() => setToggle(!toggle)}>
        menu
      </i>)}
      {toggle && (<i className="material-icons ham" onClick={() => setToggle(!toggle)}>
        close
      </i>)}

      </div>
      </div>

      {toggle && (
        <div className="menu_list">

          <Link to="/about" className='menu_text' onClick={() => setToggle(!toggle)}>
            About Us
          </Link>

          

          {/* {isLoggedIn && <Link to="/dashboard" onClick={() => setToggle(!toggle)}>
            Dashboard
          </Link>} */}

          {/* {isLoggedIn && <Link to="/game" onClick={() => setToggle(!toggle)}>
            Game
          </Link>} */}

          {/* {isLoggedIn ? <Link to="/login" onClick={() => { processLogout() ; setLoggedIn(false); setToggle(!toggle)}}>
            Logout
          </Link>  :<Link to="/login" onClick={() => setToggle(!toggle)}>
            Login
          </Link>} */}


        </div>
      )}
      
    </header>
  )
}
