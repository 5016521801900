import React, { useState, useContext } from "react";
import { useForm } from "./hooks/useForm";
import { useNavigate, Link } from "react-router-dom";
import AuthApiService from './Services/auth-service';
import { UserContext } from "./UserContext";
import Loader from './Loader/Loader'
import logo from "./dst-graphic-transparent.png";


export default function Login() {

  const { user, processLogin } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { values, handleChange } = useForm({ email: "", password: "" });
  let theme = user.theme

  const handleSubmit = (e) => {
    const { email, password } = values;
    let lowEmail = email.toString().toLowerCase()

    e.preventDefault();
    setLoading(true);

    AuthApiService.postLogin({
      email: lowEmail,
      password
    })
      .then(res => {
        processLogin(res);
        navigate('/dashboard');
      })
      .catch(res => {
        setLoading(false)
        setError(res.error);
      });
  }


  return (
    (loading ? <Loader /> :
      <div className="Login">
        <img src={logo} className="graphic" alt="logo" />

        <div role='alert' className='error-message'>
          {error && <p>{error}</p>}
        </div>

        <form className='form' onSubmit={handleSubmit}>

          <div className="input_box column">
            <label className={`text ${theme}`}>Email</label>
            <input
              className='login-input'
              autoComplete="on"
              type="email"
              name="email"
              placeholder="Enter email..."
              onChange={handleChange}
              value={values.email}
            />

          </div>

          <div className="input_box column">

          <label className={`text ${theme}`}>Password</label>
          <input
            className='login-input'
            autoComplete="on"
            type="password"
            name="password"
            placeholder="Enter password..."
            onChange={handleChange}
            value={values.password}
          />
          </div>

          <button type="submit" className='loginButton buy_btn' id="login_btn">Sign in</button>


        </form>
        <Link to='/register'>Don't have an account? Register</Link>
      </div>)
  )
}
