import React, { createContext, useState, useEffect } from "react";
import TokenService from './Services/token-service';
import UserService from './Services/user-service';

const UserContext = createContext();
const Token = TokenService.getAuthToken()

const UserContextProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({
    theme: "dark"
  });
  const [current_question, setCurrentQuestion] = useState(1);


  useEffect(() => {
    Token ? setLoggedIn(true) : setLoggedIn(false)
  }, []);

  const processRefreshUser = async () => {
    UserService.getUser().then((res) => {
      window.localStorage.setItem('user', JSON.stringify(res.user))
      setUser(res.user);
      setCurrentQuestion(res.user.current_question)
      setLoggedIn(true)
    });

   
  };


  const processLogin = async ({ authToken, dbUser }) => {
    TokenService.saveAuthToken(authToken)
    window.localStorage.setItem('user', JSON.stringify(dbUser))
    setUser(dbUser);
    setCurrentQuestion(dbUser.current_question)
    setLoggedIn(true)
  };

  const processLogout = async () => {
    TokenService.clearAuthToken();
    TokenService.clearUser();
    setUser({ theme: user.theme });
  };

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={{
      current_question, setCurrentQuestion, user, setUser, error, setError, processLogin, processLogout, processRefreshUser, isLoggedIn, setLoggedIn
    }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };