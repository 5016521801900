import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "./UserContext";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import logo from "./dizzle_sol_ico.png";
import space from "./space.webp";
import pinksale from "./pinksale-transparent.png";
import mars from "./mars .jpg";
import graphic1 from "./dst-graphic-transparent.png";
import tokenomics from "./Tokenomics Chart.png";
import "./App.css";
import Countdown from 'react-countdown';

export default function Home() {
  const { user } = useContext(UserContext);
  const [zoom, setZoom] = useState(1);
  let theme = user.theme;
 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Completionist = () => <span className="presale">Dizzle Sol is now on Presale!</span>

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return( 
      <div className="timer_wrapper">
        <div className="timer_card center">
          <p className="timer_card_title">Days</p>
          <p className="timer_card_text">{days}</p>
        </div>
        <div className="timer_card center">
          <p className="timer_card_title">Hours</p>
          <p className="timer_card_text">{hours}</p>
        </div>
        <div className="timer_card center">
          <p className="timer_card_title">Minutes</p>
          <p className="timer_card_text">{minutes}</p>
        </div>
        <div className="timer_card center">
          <p className="timer_card_title">Seconds</p>
          <p className="timer_card_text">{seconds}</p>
        </div>
      </div>
      )
    }
  };

  return (
    <section className="Home">
      <div className="section_1 column">
        
        <img
          src={space}
          className="parallax_bg"
          alt="background moon"
        />

        <img
          src={graphic1}
          className="graphic"
          alt="dizzle sol logo spinning"
        />

        <p className={`hero_text animate__animated animate__fadeInDown`}>
          <span className="blue">DIZZLE SOL</span> : THE MEME TOKEN OF THE FUTURE
        </p>

        <p className={`text animate__animated animate__fadeInUp`}>
          Introducing Dizzle Sol, the freshest meme token to hit the
          cryptocurrency scene!
        </p>

        <div className="social_media_btn_box animate__animated animate__fadeInUp">
          <SocialIcon
            url="https://twitter.com/DizzleTech"
            target="_blank"
            className="social_btn"
          />
          <SocialIcon
            url="https://t.me/dizzle_sol"
            target="_blank"
            className="social_btn"
          />
          <SocialIcon
            url="https://discord.gg/KTveXzFY"
            target="_blank"
            className="social_btn"
          />
        </div>

        {/* <button className="buy_btn animate__animated animate__fadeInUp">
          <a
            href={"https://www.pinksale.finance"}
            target="_blank"
            rel="noreferrer"
          >
            BUY TOKENS{" "}
          </a>
        </button> */}

        {/* <div className="timer_outer_wrapper animate__animated animate__fadeInUp">
          <p className="presale">Presale Begins</p>
          <Countdown date={'2024-05-03T00:01:00'} renderer={renderer} daysInHours={true} />
        </div> */}
          
      </div>

      {/* SECTION 2 */}

      <div className="section_2">
        <div className="section2_img_wrapper">
          {/* <p className={`section_title`} style={{ marginBottom: '20px' }}>Tokenomics</p> */}

          <img
            src={tokenomics}
            className="tokenomics_img"
            alt="dizzle sol tokenomics chart"
          />

          <div className="tokenomics_card column center" id="supply_card">
            <p className="tokenomics_title">Supply</p>
            <p className="tokenomics_text">Total Supply: 100,000,000</p>
            <p className="tokenomics_text">
              Initial Circulation Supply: 75,000,000
            </p>
            <p className="tokenomics_text">Liquity: Locked</p>
            <p className="tokenomics_text">Mint Authority: Frozen</p>
          </div>
        </div>

        <div className="section2_card_wrapper">
          <div className="tokenomics_card column center">
            <p className="tokenomics_title">35% Presale</p>
            <p className="tokenomics_text">
              35% of the total token supply will be set aside for the $DIZ
              presale. The intention is to allow members of the DIZ community to
              purchase tokens to supply the initial liquidity.
            </p>
          </div>

          <div className="tokenomics_card column center">
            <p className="tokenomics_title">40% DEX</p>
            <p className="tokenomics_text">
              40% of the overall supply is reserved for pairing with a liquidity
              pool on a decentralized exchange.
            </p>
          </div>

          <div className="tokenomics_card column center">
            <p className="tokenomics_title">10% CEX</p>
            <p className="tokenomics_text">
              10% of the overall supply is reserved for pairing with a liquidity
              pool on a centralized exchange.
            </p>
          </div>

          <div className="tokenomics_card column center">
            <p className="tokenomics_title">10% Team</p>
            <p className="tokenomics_text">
              10% of the overall supply is reserved for the Dizzle Dev Team.
            </p>
          </div>

          <div className="tokenomics_card column center">
            <p className="tokenomics_title">2.5% Airdrop</p>
            <p className="tokenomics_text">
              2.5% of the overall supply is reserved for airdrops to the $DIZ
              community.
            </p>
          </div>

          <div className="tokenomics_card column center">
            <p className="tokenomics_title">2.5% Community Rewards</p>
            <p className="tokenomics_text">
              2.5% of the overall supply is reserved for rewards to the $DIZ
              community.
            </p>
          </div>
        </div>
      </div>

      {/* SECTION 3 */}
      <div className="section_3">

        <p className={`section_title`}>Game Plan</p>
        <div className="section_3_wrapper">
          <div className="gameplan_card column center">
            <p className="gameplan_title">Stage 1</p>
            <p className="gameplan_text">Mint Token</p>
            <p className="gameplan_text">Host Presale</p>
            <p className="gameplan_text">Start Building a community</p>
            <p className="gameplan_text">Launch Token</p>
          </div>

          <div className="gameplan_card column center">
            <p className="gameplan_title">Stage 2</p>
            <p className="gameplan_text">Scale Up Ecosystem</p>
            <p className="gameplan_text">Create Rewards Program</p>
            <p className="gameplan_text">List token on DEXs</p>
            <p className="gameplan_text">Prepare to launch on CEX platforms</p>
          </div>

          <div className="gameplan_card column center">
            <p className="gameplan_title">Stage 3</p>
            <p className="gameplan_text">Launch to centralized exchanges</p>
            <p className="gameplan_text">
              Secure partnerships with social media influencers
            </p>
            <p className="gameplan_text">
              Explore Development of a new blockchain
            </p>
            <p className="gameplan_text">Expand the Dizzle Sol team</p>
          </div>
        </div>

        <div className="disclaimer">
          <p className="disclaimer_text">
            Before proceeding with your investment decision, we want to
            emphasize several important points regarding our cryptocurrency
            token. Investing in cryptocurrencies carries inherent risks, and it
            is crucial to understand these risks before committing any funds.
            Please carefully consider the following disclaimer:
          </p>
          <p className="disclaimer_text">
            Risk of Loss: Investing in cryptocurrencies involves the risk of
            financial loss. The value of our token can fluctuate widely, and
            there is no guarantee of profit or preservation of capital. It is
            possible to lose a significant portion, or even all, of your
            investment.
          </p>
          <p className="disclaimer_text">
            Market Volatility: Cryptocurrency markets are highly volatile and
            can experience rapid price changes. Factors such as market
            sentiment, regulatory developments, technological advancements, and
            macroeconomic conditions can all contribute to price volatility.
          </p>
          <p className="disclaimer_text">
            No Guarantees: Our cryptocurrency token does not guarantee any
            returns. Past performance is not indicative of future results, and
            the value of the token can change rapidly based on market dynamics.
          </p>
          <p className="disclaimer_text">
            Investment Suitability: Only invest money that you can afford to
            lose. Consider your financial situation, risk tolerance, and
            investment goals before investing in our token. Cryptocurrency
            investments should be part of a diversified investment portfolio.
          </p>
          <p className="disclaimer_text">
            Regulatory Risks: Regulatory changes or interventions in the
            cryptocurrency space can impact the value and legality of our token.
            Investors should stay informed about regulatory developments that
            may affect their investments.
          </p>
          <p className="disclaimer_text">
            Due Diligence: Conduct thorough research and seek advice from
            financial professionals before making any investment decisions.
            Understand the technology, team, use case, and potential risks
            associated with our token.
          </p>
          <p className="disclaimer_text">
            By investing in our cryptocurrency token, you acknowledge and accept
            these risks. We encourage you to make informed investment decisions
            and to seek independent financial advice if needed.
          </p>
        </div>
      </div>
    </section>
  );
}
