import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { Buffer } from 'buffer/';
import GameService from './Services/game-service';

export default function Game() {
  const [question, setQuestion] = useState(null);
  const [questionStats, setQuestionStats] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [isAnswerCorrect, setisAnswerCorrect] = useState(null);
  const [userAnswer, setUserAnswer] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { user, current_question, setCurrentQuestion, isLoggedIn } = useContext(UserContext);
  let theme = user.theme

  useEffect(() => {
    return (window.scrollTo(0, 0))
  }, [userAnswer])

  useEffect(() => {
    if (userAnswer){
      navigate('/login')
    }
  }, [])
  useEffect(() => {
    if (!isLoggedIn){
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    if(isLoggedIn){GameService.getQuestionById(current_question)
    .then((res) => {
        setQuestion(res)
        console.log(res);
    
    }).catch(res => {
      setQuestion(null);
      setError(true);
      navigate('/login')
    })
  }
  }, [])

  // useEffect(() => {
  //   GameService.getQuestionById(current_question)
  //   .then((res) => {
  //       setQuestion(res)
  //     });
  // }, [])

  useEffect(() => {
    GameService.getQuestionById(current_question).then(
      (res) => {
        setQuestion(res)
      })
      .catch(res => {
        setQuestion(null);
        setError(true);
      });
  }, [current_question])
  

  //PICTURE ENCODER
  const base64Encode = (file) => {
    return Buffer.from(file).toString('base64')
  };

  const finished = () => {
    setCurrentQuestion(1)
    navigate('/dashboard');
  }

  const next = () => {
    setCurrentQuestion(current_question + 1)
    setUserAnswer(null)
    setisAnswerCorrect(null)
  }

  const submitAnswer = (ans) =>{
    //user stats
    let correct_questions = 0
    let loses = 0
    let wins = 0
    let wrong_questions = 0

    //question stats
    let buy = 0
    let correct = 0
    let sell = 0
    let  wrong = 0

    setUserAnswer(ans)

    //set User stats to be sent to API
    if(ans === question.answer){
      setisAnswerCorrect(true)
      correct= 1 
      wins = 1
      correct_questions = current_question
    }
     
    if(ans !== question.answer){
      setisAnswerCorrect(false)
      loses = 1 
      wrong_questions = current_question
      wrong = 1
    }
      //set Question stats to be sent to API
    if (ans !== question.answer){
    wrong = 1 
    }

    if(ans === "buy"){
       buy = 1
      }
    
    if(ans === "sell"){
      sell = 1
    }

    let userStats = {
      correct_questions,
      loses,
      wins,
      wrong_questions,
    }
    
    let questionStats = {
      buy,
      correct,
      sell,
      wrong,
    }

    let stats = {userStats, questionStats}

    GameService.updateQuestionStats(current_question, stats)
      .then((res) => {
        setQuestionStats(res.question)
        setUserStats(res.user_stats)
      });

  }

  return (
    <div className="gameboard center column">
      {/* <h1 className={`text  ${theme}`}> Game </h1> */}
      {isAnswerCorrect && <p className={`text green`}>You made a prophet!</p>}
      {isAnswerCorrect === false && <h2 className={`text red`}>You made did not make a prophet.</h2>}
    
      {userStats && userAnswer && 
      <div className="stat_box row">
          <div className="column"> <p className={`text  green`}> Wins </p> <p>{userStats.wins}</p></div> 
          <div className="column"> <p className={`text  red`}> Loses </p> <p>{userStats.loses}</p></div> 
          <div className="column"> <p className={`text green`}> Buys </p> <p>{questionStats.buy}</p></div> 
          <div className="column"> <p className={`text  red`}> Sells </p> <p>{questionStats.sell}</p></div> 
        </div>}

      {error &&
        <div className="column center">
          <p className={`text  ${theme}`}>There are no more questions at the moment</p>
          <button className="buy_btn" onClick={() => { finished() }}>Go to Dashboard</button>
        </div>}

     {question &&
     <div>
          
         {!userAnswer && <img
            className="qimage"
            src={`data:image/jpg;base64,${base64Encode(question.qimage)}`}
            loading='lazy'
            alt="question"
          />}
         {userAnswer && <img
            className="qimage"
            src={`data:image/jpg;base64,${base64Encode(question.aimage)}`}
            loading='lazy'
            alt="answer"
          />}
          {userAnswer &&
            <div>
              <h3 className={`text  ${theme}`}> Technical Analysis </h3>
              <p className={`text  ${theme}`}>{question.answer_description}</p>
            </div>
          }
          {userAnswer && <div className="btn_box row">
            <button className="buy_btn" onClick={() => { next() }}>Next</button>
          </div>}
          {question && !userAnswer && <div className="btn_box row">
          <button className="game_btn green" onClick={() => {submitAnswer('buy')}}>Buy</button>
          <button className="game_btn red" onClick={() => { submitAnswer("sell") }}>Sell</button>
          </div>}

      

        </div>
        }

    </div>
  )
}
