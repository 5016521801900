import React, {  useContext } from "react";
import { Routes, Route } from "react-router-dom";
import { UserContext } from "./UserContext";
import bg from "./dst-graphic.jpg";

import About from './About'
import Dashboard from './Dashboard'
import Header from './Header'
import Home from './Home'
import Game from './Game'
import Login from './Login'
import Register from './Register'
import "./App.css";

function App() {
  const {user} = useContext(UserContext);
  let theme = user.theme

  return (
    <div className={"App" + " " + theme} >
      <Header />
      <Routes>
        <Route path="about" element={<About />} />
          <Route path="/game" element={<Game />}/>
        <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/register" element={<Register />}/>
          
          <Route path="/dashboard" element={<Dashboard />}/>
      </Routes>
    </div>
  );
}

export default App;
