import React, {
  useContext
} from "react";
import CONFIG from '../config';
import TokenService from './token-service';
import {UserContext} from '../UserContext'



const GameService = {
  async getQuestionById(qid) {
    return fetch(`${CONFIG.REACT_APP_URL}/questions/${qid}`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`
      }
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(err => Promise.reject(err))
          : res.json()
      )
  },
  async getAllQuestions() {
    return fetch(`${CONFIG.REACT_APP_URL}/questions`, {
      method: 'GET',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`
      }
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(err => Promise.reject(err))
          : res.json()
      )
  },
  async updateQuestionStats(qid,stats) {
    return fetch(`${CONFIG.REACT_APP_URL}/questions/${qid}`, {
      method: 'PUT',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`,
        'content-type': 'application/json'
      },
      body: JSON.stringify(stats)
    })
    .then(res =>
      (!res.ok)
        ? res.json().then(e => Promise.reject(e))
        : res.json()
    );
  },
  createQuestion(obj) {
    return fetch(`${CONFIG.REACT_APP_URL}/questions`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`
      },
      body: obj
    })
      .then(res => {
        if (!res.ok) {
          res.json().then(e => Promise.reject(e))
        } else {
          res.json()
        }
      }
      );
  },
  editQuestions(obj) {
    return fetch(`${CONFIG.REACT_APP_URL}/questions`, {
      method: 'PATCH',
      headers: {
        'authorization': `Bearer ${TokenService.getAuthToken()}`
      },
      body: obj,
    })
      .then(res =>
        (!res.ok)
          ? res.json().then(e => Promise.reject(e))
          : res.json()
      );
  },
}
export default GameService;
