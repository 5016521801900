import React from 'react'
import './Loader.css'

const Loader = () => {
  return (
    <section className='loader-container'>
    <div className='loader'>
    </div>
    <span>Loading</span>
    </section>
  )
}

export default Loader